/* dublignn */




/* Color Variables */
:root {
    /* Existing Colors */
    --bg-color: #f9f9f9;
    --white: #fff;
    --border-color: #ccc;
    --highlight-border: #c9c935;
    --error-color: red;
    
    /* Existing Colors (Hover and Active) */
    --green: #abf1ae;
    --green-hover: #98e99c; /* Darker Green */
    --red: #f44336;
    --red-hover: #71dc07; /* Darker Red */
    --blue: #2196F3;
    --blue-hover: #1976D2; /* Darker Blue */
    --purple: #895692; /* Purple Color */
    --purple-hover: #8E24AA; /* Darker Purple */
    
    /* Additional Colors */
    --yellow: #FFEB3B; /* Bright Yellow */
    --yellow-hover: #FBC02D; /* Darker Yellow */
    --orange: #FF9800; /* Vibrant Orange */
    --orange-hover: #F57C00; /* Darker Orange */
    --cyan: #00BCD4; /* Cyan Color */
    --cyan-hover: #0097A7; /* Darker Cyan */
    --pink: #E91E63; /* Bright Pink */
    --pink-hover: #C2185B; /* Darker Pink */
    --grey: #9E9E9E; /* Neutral Grey */
    --grey-hover: #757575; /* Darker Grey */
    
    /* Additional Gradient Colors */
    --gradient-primary: linear-gradient(45deg, var(--blue), var(--purple));
    --gradient-secondary: linear-gradient(45deg, var(--yellow), var(--orange));
}


/* Main Form Styling    now names as Create New Template */
.due-diligence-form {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 20px auto; /* Center the form vertically, adjust as needed */
    margin-left: 280px; /* Align the container to the right */
    margin-right: 25px; /* Ensure there's no right margin */
    padding: 20px; /* General padding around the form */
    background-color: var(--bg-color);
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(227, 153, 153, 0.1);
}


/* Main Heading Styling */
.main-heading {
  text-align: center; /* Center text in the heading */
  padding-left: 60px;
  font-size: 2.5rem; /* Large text */
  margin-bottom: 20px;
  color: #333;
  max-width: 50%; /* Adjusts the maximum width of the heading */
  margin-left: auto; /* Centering the heading */
  margin-right: auto; /* Centering the heading */
  padding: 1px 1px; /* Adds horizontal padding */
  padding-left: 50px;
  padding-right: 10px;
  display: flex;
  padding-top: 15px;
}

.input-centered {
  text-align: center; /* Center text in the input */
  width: 100%; /* Full width */
  padding: 10px; /* Add some padding */
  font-size: 1.5rem; /* Adjust font size for the input */
  margin: 0 auto; /* Center the input */
}


/* Subheading Input Styling */
.subheading-input {
    width: 50%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
}

/* Input Fields */
.heading-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
}

/* Report Fields Section Styling */
.report-fields {
    margin-bottom: 26px; /* Space between each field section */
    padding: 14px; /* Padding inside the fields for better design */
    border-radius: 4px; /* Slight curve for better design */
    background-color: var(--white); /* Background color for separation */
    border: 1px solid var(--border-color); /* Subtle border */
    display: flex; /* Use flexbox for alignment */
    flex-direction: column; /* Stack children vertically */
}

/* Adjust padding and spacing for report fields */
.report-fields {
    padding: 14px; /* Ensure consistent padding around all sides */
}
.report-field-container {
  display: flex; /* Use flexbox for row layout */
  align-items: center; /* Center align items vertically */
  justify-content: space-between; /* Space between items */
  margin-bottom: 15px; /* Space between each field */
}

/* Ensure space between input fields and buttons */
.report-field-container input {
  flex: 1; /* Allow input to grow and take up available space */
  margin-right: 10px; /* Space between the input and button */
  min-width: 200px; /* Set a minimum width for better control */
}

/* Button Styling */
.report-field-container button {
  margin-left: 2px; /* Space between buttons */
}

/* Optionally, for button alignment */
.button-container {
  display: flex; /* Align buttons in a row */
  align-items: center; /* Center align buttons vertically */
}

/* Button Styling for Add Field */
.report-fields button.add-field-button {
    width: 120px; /* Decrease the width of the Add Field button */
    padding: 10px 11px; /* Adjust padding for the button size */
    font-size: 0.85rem; /* Adjust font size */
    background-color: rgb(164, 185, 218); /* Button background color */
    color: rgb(21, 27, 19); /* Button text color */
    border: 1px solid var(--white); /* Border style */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer on hover */
    transition: background-color 0.2s ease-in-out, transform 0.1s; /* Hover transition */
}
.add-field-button{
  width: 138px; /* Decrease the width of the Add Field button */
  padding: 10px 11px; /* Adjust padding for the button size */
  font-size: 0.85rem; /* Adjust font size */
  background-color: rgb(164, 185, 218); /* Button background color */
  color: rgb(21, 27, 19); /* Button text color */
  border: 1px solid var(--white); /* Border style */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer on hover */
  transition: background-color 0.2s ease-in-out, transform 0.1s; /* Hover transition */
}
.add-field-button:hover {
  background-color: #00BFFF; /* Darker blue on hover */
  transform: scale(0.98); /* Slight shrink on hover */
}
/* Hover effect for Add Field button */
.report-fields button.add-field-button:hover {
    background-color: #00BFFF;/* Darker blue on hover */
    transform: scale(0.98); /* Slight shrink on hover */
}

/* Additional styling to adjust placeholder text */
.report-fields input::placeholder,
.report-fields select::placeholder,
.report-fields textarea::placeholder {
    padding-left: 1px; /* Optional: Adjust the padding of placeholder text */
    color: #999; /* Optional: Change the color of the placeholder text */
}


/* Hover effect for Edit button */
.edit-button:hover {
    background-color: rgb(164, 185, 218);
    transform: scale(0.98);
}

/* Remove Button Styling (if needed) */
.remove-button {
    background-color: rgb(164, 185, 218);
    padding: 10px 16px;
    font-size: 0.85rem;
    border: 1px solid var(--white);
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, transform 0.1s;
    margin-left: 10px;
}

/* Hover effect for Remove button */
.remove-button:hover {
    background-color: rgb(164, 185, 218);
    transform: scale(0.98);
}

/* Field Containers */
.field-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    border: 1px solid var(--highlight-border);
    border-radius: 4px;
    padding: 10px;
    background-color: var(--white);
}

/* Input Group */
.input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

/* Button Container */
.button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

/* Common Button Styles */
button {
    padding: 10px 16px; /* Increase padding for a more comfortable button size */
    border: none;
    border-radius: 6px; /* Increase border-radius for softer, rounder edges */
    color: rgb(21, 27, 19); /* Ensure button text remains white */
    cursor: pointer;
    margin: 5px;
    transition: background-color 0.3s ease; /* Add a smooth transition for hover effects */
}

/* Specific Button Styles */
.add-button {
    background-color: rgb(164, 185, 218); /* Green button */
    padding: 4px 6px; /* Smaller padding for a smaller green button */
    width: 110px; /* Slightly adjusted width */
    border-radius: 19px; /* Increased border-radius for rounder corners */
    padding-left: 10px;
}

.remove-button {
    background-color: rgb(164, 185, 218);; /* Red button */
    border-radius: 8px; /* Slightly rounder corners */
}

.submit-button {
    background-color: rgb(164, 185, 218);
    border-radius: 6px; /* Slightly rounder corners */
    padding: 2px 5px;  /* Smaller padding for reduced size */
    font-size: 1.0rem;  /* Smaller font size */
    width: 130px;  /* Fixed small width for the button */
    height: 40px; /* Fixed small height */
}

.subject-info-button {
  background-color: rgb(164, 185, 218); /* Purple button */
  border-radius: 8px; /* Rounder edges for all buttons */
  width: 120px;
  height: 40px;
  padding: 10px;
  text-align: center; /* Centers the text horizontally */
  display: flex; /* Use flexbox for centering */
  align-items: center; /* Centers the text vertically */
  justify-content: center; /* Centers the text horizontally */
}


/* Hover Effects */
.add-button:hover {
    background-color: var(--green-hover); /* Darker green on hover */
}

.remove-button:hover {
    background-color: #00BFFF;/* Darker red on hover */
}

.submit-button:hover {
    background-color: #00BFFF; /* Darker blue on hover */
}

.subject-info-button:hover {
    background-color: #00BFFF; /* Darker purple on hover */
}


/* Error Message */
.error {
    color: var(--error-color);
    font-size: 12px;
}

/* Section Container */
.section-container {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .due-diligence-form {
        padding: 10px;
    }
}






.poops {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(218, 226, 193, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.3s ease;
}

.pop-container {
  background: #f0f4ff; /* Light blue background */
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 400px; /* Set a max width for the popup */
  animation: fadeIn 0.3s ease;
}

.displaygirdpop {
  display: flex;
  flex-direction: column;
}

.headingconatiner {
  color: #000; /* Black text */
  background-color: #afb5ba; /* Soft light blue */
  align-items: center;
  padding-left: 75px;
  padding-top: 4px;
}

.custom-select,
.custom-input {
  width: 90%;
  padding: 10px; /* Reduced padding for better fit */
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.custom-select:focus,
.custom-input:focus {
  border-color: #a0acba; /* Highlight border on focus */
  outline: none;
}

.pop-button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 23px;
}

.pop-submit-button,
.pop-cancel-button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
}

.pop-submit-button {
  background-color: #a1a8af; /* Bright blue */
  color: #fff;
}

.pop-submit-button:hover {
  background-color: #cedeed; /* Light blue on hover */
  transform: scale(1.05); /* Slight zoom effect */
}

.pop-cancel-button {
  background-color: #f44336; /* Red for cancel */
  color: #fff;
}

.pop-cancel-button:hover {
  background-color: #c62828; /* Darken on hover */
  transform: scale(1.05); /* Slight zoom effect */
}

/* Fade-in animation for popup */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}



/* assets form css
 */
 /* Apply box-sizing globally */
*,
*::before,
*::after {
  box-sizing: border-box; /* Include padding and border in width/height */
}

body {
  font-family: 'Arial', sans-serif; /* Changed to Arial for consistency */
  line-height: 1.6; /* Improved line spacing for readability */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  background-color: #f1f1f1; /* Light background for contrast */
}

/* Main container for the report template */
.newchar-report-container {
  max-width: 1100px;
  margin: 20px auto; /* Center the component */
  padding: 15px; /* Increased padding for spaciousness */
  background-color: #f9f9f9; /* Light background for the form */
  border-radius: 10px; /* More pronounced rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Enhanced shadow for depth */
  color: #333; /* Dark text color for readability */
}

/* Header section styling */
.newchar-report-header {
  margin-bottom: 25px; /* Space between header and sections */
  /* text-align: center; Centered header text */
}

/* Container for input fields */
.newchar-input-fields {
  display: flex; /* Use flexbox for alignment */
  flex-direction: column; /* Stack fields vertically */
}

/* Field container styling */
.newchar-field-group {
  margin-bottom: 15px; /* Space between field containers */
}

.newchar-field-group label {
  margin-bottom: 5px; /* Space below the label */
  font-weight: bold; /* Bold labels for emphasis */
  color: #007bff; /* Change label color for visibility */
}

.newchar-field-group input,
.newchar-field-group textarea {
  width: 100%; /* Full width */
  padding: 8px; /* Comfortable padding */
  border: 1px solid #ccc; /* Border style */
  border-radius: 6px; /* Rounded corners */
  transition: border-color 0.3s, box-shadow 0.3s; /* Transition for focus state */
}

/* Focus styles for inputs */
.newchar-field-group input:focus,
.newchar-field-group textarea:focus {
  border-color: #007bff; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Subtle glow on focus */
  outline: none; /* Remove default outline */
}

/* Section styling */
.newchar-report-section {
  margin-bottom: 15px; /* Space below each section */
  border: 1px solid #ddd; /* Light border around sections */
  padding: 10px; /* Padding inside sections */
  border-radius: 6px; /* Rounded corners */
  background-color: #fff; /* White background for sections */
  transition: background-color 0.3s ease, box-shadow 0.3s; /* Smooth transitions */
}

/* Button Container */
.report-button-container {
  display: flex;
  justify-content: flex-start; /* Align buttons to the start */
  gap: 875px; /* Adjusted space between buttons */
  margin-top: 10px; /* Space above the button container */
}

/* Base button styles */
.newchar-button {
  background-color: #5ab641; /* Blue background */
  color: white; /* White text */
  border: none; /* Remove border */
  padding: 8px 15px; /* Reduced padding */
  margin-top: 18px; /* Space above the button */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 4px; /* Slightly smaller border radius */
  font-size: 0.85em; /* Smaller font size */
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s; /* Smooth transitions */
  box-shadow: 0 2px 5px rgba(250, 205, 205, 0.2); /* Subtle shadow */
  gap: 820px; 
}

/* Specific button styles */
.newchar-button.remove {
  background-color: #d32f2f; /* Red background for remove */
  padding-left: 14px;
  color: white; /* White text */
  border: none; /* Remove border */
  padding: 8px 15px; /* Reduced padding */
  margin-top: 18px; /* Space above the button */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 4px; /* Slightly smaller border radius */
  font-size: 0.85em; /* Smaller font size */
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s; /* Smooth transitions */
  box-shadow: 0 2px 5px rgba(230, 169, 169, 0.2); /* Subtle shadow */
}

.newchar-button.add {
  background-color: #5ab641; /* Blue background */
  color: white; /* White text */
  border: none; /* Remove border */
  padding: 8px 15px; /* Reduced padding */
  margin-top: 18px; /* Space above the button */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 4px; /* Slightly smaller border radius */
  font-size: 0.85em; /* Smaller font size */
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s; /* Smooth transitions */
  box-shadow: 0 2px 5px rgba(250, 205, 205, 0.2); /* Subtle shadow */
  gap: 820px; 
}

.newchar-button.hidden-assets {
  background-color: #007bff; /* Primary blue */
  color: white; /* White text */
}

/* Hover effects for buttons */
.newchar-button:hover {
  opacity: 0.9; /* Slightly darker on hover */
  transform: translateY(-2px); /* Lift effect on hover */
}

/* Active effects */
.newchar-button:active {
  transform: translateY(1px); /* Press down effect */
}

/* Disabled state for buttons */
.newchar-button:disabled {
  background-color: #c0c0c0; /* Grey background */
  cursor: not-allowed; /* Change cursor to indicate it's disabled */
  opacity: 0.7; /* Reduce opacity for disabled state */
}

/* Error message styles */
.error {
  color: rgb(255, 0, 0); /* Red color for error messages */
  font-size: 0.875em; /* Font size for error messages */
  margin-top: 4px; /* Space above error message */
}

/* Add icon styling */
.add-icon {
  margin-left: 5px; /* Space to the left of the icon */
  cursor: pointer; /* Pointer cursor */
  font-size: 1.5em; /* Icon size */
  color: #e97522; /* Icon color */
}




/* reportdisplay not in use  */

/* Ishan Srivastava, [24-09-2024 23:28] */
/* Report Container Styling */
.report-container {
    width: 100%; /* Full width */
    max-width: 1200px; /* Maximum width for large screens */
    margin: 20px auto; /* Center and add margin */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Add slight shadow */
    border-radius: 8px; /* Smooth corners */
  }
  
  /* Vertical Report Styling */
  .vertical-report {
    width: 100%; /* Full width */
    margin: 20px auto; /* Center and add margin */
    border: 1px solid black; /* Border on all sides */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Add slight shadow */
    background-color: #fff; /* Ensure white background */
    border-radius: 8px; /* Smooth corners */
    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack children vertically */
    align-items: flex-start; /* Align items to the start (top) */
  }
  
  /* Horizontal Report Styling */
  .horizontal-report {
    width: 100%; /* Full width */
    margin: 20px auto; /* Center and add margin */
    border: 1px solid black; /* Border on all sides */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Add slight shadow */
    background-color: #fff; /* Ensure white background */
    border-radius: 8px; /* Smooth corners */
  }
  
  /* Vertical Report Table Styling */
  .vertical-report-table {
    width: 100%; /* Full width for the table */
    border-collapse: collapse; /* Collapse borders for a cleaner look */
    margin-top: 20px; /* Space between the top of the report and the table */
  }
  
  .vertical-report-table th,
  .vertical-report-table td {
    border: 1px solid black; /* Border for table cells */
    padding: 15px; /* Padding for table cells */
    text-align: left; /* Align text to the left */
  }
  
  /* Header Styling */
  .vertical-report-table th {
    background-color: black; /* Background color for header */
    color: white; /* Text color for header */
    font-weight: bold; /* Bold font for header */
  }
  
  /* Company Row Styling */
  .company-row {
    display: flex;
    border-bottom: 1px solid black; /* Bottom border between rows */
    flex-wrap: wrap; /* Wrap for smaller screens */
  }
  
  .company-row:last-child {
    border-bottom: none; /* Remove border from the last row */
  }
  
  /* Company Label and Value Styling */
  .company-label {
    width: 25%; /* Label takes 25% of the row */
    background-color: black; /* Background color for label */
    color: white; /* Text color for label */
    padding: 15px; /* Padding for labels */
    font-weight: bold; /* Bold font for label */
    box-sizing: border-box; /* Include padding in width calculation */
    border-right: 1px solid black; /* Border between label and value */
  }
  
  .company-value {
    width: 75%; /* Value takes 75% of the row */
    padding: 15px; /* Padding for values */
    background-color: white; /* Background color for values */
    box-sizing: border-box; /* Include padding in width calculation */
  }
  
  /* Summary Table Styling */
  .company-infor {
    width: 100%; /* Full width */
    max-width: 1200px; /* Maximum width for large screens */
    margin: 30px auto; /* Center and add margin */
    border: 1px solid black; /* Border on all sides */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Add slight shadow */
    background-color: #fff; /* Ensure white background */
    border-radius: 8px; /* Smooth corners */
  }
  
  .company-row-summary {
    display: flex; /* Use Flexbox for single row alignment */
    border-bottom: 4px solid black; /* Bottom border between rows */
    padding: 12px;
  }
  
  .company-row-summary:last-child {
    border-bottom: none; /* Remove border from the last row */
  }
  
  .company-labels, .company-values {
    flex: 1; /* Allow labels and values to take equal space */
    padding: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
  }
  
  .company-labels {
    background-color: black; /* Label background */
    color: white; /* Label text color */
    border-right: 1px solid white; /* Separate columns visually */
  }
  
  .company-values {
    background-color: white; /* Value background */
    color: black; /* Value text color */
    font-weight: normal;
    text-align: center;
  }
  
  .company-values:not(:last-child) {
    border-right: 1px solid #ddd; /* Light border for separation */
  }
  
  /* Ishan Srivastava, [24-09-2024 23:28] */
  /* Button Styling */
  .show-pdf-button {
    padding: 10px 20px; /* Add padding for the button */
    background-color: #2980b9; /* Blue background */
    color: #fff; /* White text */
    border: none; /* No border */
    border-radius: 5px; /* Smooth corners */
    cursor: pointer; /* Pointer cursor */
    font-size: 16px; /* Larger font size */
    font-weight: bold; /* Bold text */
    transition: background-color 0.3s ease; /* Smooth background transition */
    margin-bottom: 10px; /* Space below the button */
    position: absolute; /* Position the button */
    top: 20px; /* Distance from the top */
    right: 20px; /* Distance from the right */
  }
  
  .show-pdf-button:hover {
    background-color: #1a6e96; /* Darker blue on hover */
  }
  
  .show-pdf-button:active {
    background-color: #145574; /* Even darker blue on click */
    box-shadow: none; /* Remove shadow when clicked */
  }
  
  /* Media Queries for Smaller Screens */
  @media (max-width: 768px) {
    .company-label,
    .company-value {
      width: 100%; /* Full width for both on smaller screens */
      padding: 10px; /* Adjust padding for smaller screens */
      border-right: none; /* Remove side border on smaller screens */
    }
  
    .company-row,
    .company-row-summary {
      border-bottom: 1px solid black; /* Bottom border for rows */
    }
  
    .vertical-report,
    .horizontal-report,
    .company-infor {
      width: 100%; /* Full screen width on mobile */
      margin: 10px 0; /* Reduce margin for smaller screens */
    }
  }
  
  /* Additional Vertical Report Styling */
  .vertical-report-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
  }
  
  /* Styling for table headers */
  .vertical-report-table th {
    background-color: black;
    color: white;
    font-weight: bold;
    text-align: left;
    padding: 10px 20px;
    font-size: 16px;
    text-transform: uppercase;
    border: 1px solid black;
  }
  
  /* Styling for table cells */
  .vertical-report-table td {
    padding: 10px 20px;
    text-align: left;
    border: 1px solid black;
    font-size: 14px;
    color: black;
    background-color: white;
    vertical-align: top;
  }
  
  /* Styling for company name and other key details */
  .company-value {
    font-size: 14px;
    padding: 10px;
    color: black;
    text-align: left;
    background-color: #f5f5f5;
  }
  
  /* Styling for the management section with stacked names */
  .company-value div {
    margin-bottom: 5px;
  }
  
  /* Styling for each management role */
  .company-value div:last-child {
    margin-bottom: 0;
  }
  
  /* Media Queries for responsiveness on smaller screens */
  @media (max-width: 768px) {
    .vertical-report {
      width: 95%;
      margin: 10px auto;
    }
  
    .vertical-report-table th, .vertical-report-table td {
      padding: 8px;
      font-size: 12px;
    }
  
    .company-value {
      font-size: 12px;
    }
  }
















  /* final report used or displayed */

  .company-info {
    width: 100%; /* Full width */
    max-width: 1100px; /* Maximum width for large screens */
    margin: 16px 0 20px auto; /* Center and add margin */
    border: 1px solid black; /* Border on all sides */
    border-collapse: collapse;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Add slight shadow */
    background-color: #fff; /* Ensure white background */
    border-radius: 10px; /* Smooth corners */
    /* padding-left: 10px; */





  
    /* margin: 20px 0 20px auto; 
    float: right;
    border: 1px solid black; 
    border-collapse: collapse;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff; 
    border-radius: 10px; 
    padding-left: 10px; */
  }
  
  .company-row {
    display: flex;
    border-bottom: 1px solid black; /* Bottom border between rows */
    flex-wrap: wrap; /* Wrap for smaller screens */
  }
  
  .company-row:last-child {
    border-bottom: none; /* Remove border from the last row */
  }
  
  .company-label {
    width: 270px; /* Label takes 25% of the row */
    background-color: #111211 !important; 
    color: white;
    padding: 29px;
    font-weight: bold;
    box-sizing: border-box; /* Include padding in width calculation */
    border-right: 1px solid black; /* Border between label and value */
  }
  
  .company-value {
    width: 75%; /* Value takes 75% of the row */
    padding: 15px;
    background-color: white;
    box-sizing: border-box; /* Include padding in width calculation */
  }
  
  ul {
    padding-left: 20px;
    margin: 0;
  }
  
  ul li {
    list-style-type: disc;
  }
  
  /* Media Queries for smaller screens */
  @media (max-width: 1000px) {
    .company-label,
    .company-value {
      width: 100%; /* Full width for both on smaller screens */
      padding: 10px;
      border-right: none; /* Remove side border on smaller screens */
    }
  
    .company-info {
      width: 100%; /* Full screen width on mobile */
      margin: 10px 0; /* Reduce margin for smaller screens */
    }
  
    .company-row {
      border-bottom: 1px solid rgb(244, 238, 238);
    }
  }
  
  /* Show PDF Button Styling */
  .show-pdf-button {
    padding: 10px 20px;
    background-color: #2980b9; /* Blue background */
    color: #fff; /* White text */
    border: none;
    border-radius: 5px; /* Rounded corners */
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    margin-right: 30px; /* Add margin to move it away from the edge */
  }
  
  /* Hover effect for the button */
  .show-pdf-button:hover {
    background-color: #1a6e96; /* Darker blue on hover */
  }
  
  /* Active effect for the button */
  .show-pdf-button:active {
    background-color: #145574; /* Even darker blue on click */
    box-shadow: none; /* Remove shadow on click */
  }
  
  
  
  
  
  /*  css summary  */
  .vertical-summary-table {
    width: 100%;
    max-width: 1350px; /* Set a maximum width for larger screens */
    border-collapse: collapse; /* Ensure borders are merged */
    font-family: Arial, sans-serif; /* Use a clean font */
    display: flex; /* Maintain flex display */
    padding-left: 10px; /* Add padding for left alignment */
   
    border: 1px solid #000; /* Solid black border around the entire table for sharp corners */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Optional: Subtle shadow for better visual separation */
    background-color: #fff; /* Set white background for the whole table */
    min-height: 400px; /* Set a minimum height for the table */
    padding-left: 207px;
    margin: 16px 0 20px 20px;
  }
  
  /* Row styling */
  .vertical-summary-row {
    align-items: center; /* Center align items vertically */
    display: flex; /* Maintain flex display */
    flex-direction: column; /* Stack labels on top and values below */
    padding: 1px 0; /* Improved vertical padding for better spacing */
    text-align: left; /* Left align text */
    border-bottom: 1px solid #d3d3d3; /* Light gray border between rows */
    border-left: 1px solid #d3d3d3; /* Light gray border */
    border-right: 1px solid #d3d3d3; /* Light gray border */
  }
  
  /* Column label styling */
  .vertical-summary-label {
    font-weight: bold; /* Bold text for labels */
    padding: 12px 10px; /* Maintain padding for readability and improve spacing */
    background-color: #2980b9; /* Change background color to blue */
    color: #fff; /* White text */
    width: 100%; /* Full width for stacked view */
    border: 1px solid #d3d3d3; /* Light gray border around label */
  }
  
  /* Value styling for individual rows */
  .vertical-summary-value {
    padding: 15px 10px; /* Maintain padding for readability and improve spacing */
    background-color: #fff; /* White background */
    color: #000; /* Black text */
    width: 100%; /* Full width for stacked view */
    
   border-right: 1px solid #d3d3d3; 
  }
  
  /* Handle empty fields with a cleaner look */
  .vertical-summary-value.empty {
    background-color: #f5e7e7; /* Slight gray background for empty fields */
  }
  
  /* General field styling inside values */
  .vertical-summary-field {
    margin-bottom: 20px; /* Adjusted bottom margin for spacing */
    width: 100%; /* Full width for better structure */
  }
  
  /* Styling for text within the values */
  .vertical-summary-row .vertical-summary-field strong {
    font-weight: bold; /* Bold text for strong elements */
  }
  
  /* Media Queries for smaller screens */
  @media (max-width: 768px) {
    .vertical-summary-row {
      flex-direction: column; /* Stack items vertically on smaller screens */
    }
    .vertical-summary-label,
    .vertical-summary-value {
      width: 100%; /* Full width for both on smaller screens */
    }
  }
  
  
  /* Container for the entire summary table */
  /* Container for the entire summary table */
  /* Outer container for the blue background */
  /* Outer container for the blue background */
  /* Outer container for the table */
  /* Outer table container with black border */
  .summary-table-container {
    width: 100%;
    max-width: 1100px; /* Set max width for the container */
    font-family: 'Arial', sans-serif;
    box-shadow: 0 2px 10px rgba(172, 115, 115, 0.1);
    border-radius: 8px; /* Rounded corners */
    margin: 20px 0 5px auto; /* Center the container */
    background-color: #f9f9f9; /* Light background */
    padding: 0; /* Remove padding to make it a full table */
    border: 2px solid #000; /* Black border around the entire table */
  }
  
  /* Table styling (full width) */
  .summary-table {
    width: 100%;
    border-collapse: collapse; /* Ensure borders collapse into a single line */
    border: 2px solid #000; /* Black border around the entire table */
  }
  
  /* Row styling with black borders between rows */
  .summary-table-row {
    display: flex; /* Use flexbox for alignment */
    padding: 0; /* Remove padding for rows to eliminate gaps */
    text-align: left;
    transition: background-color 0.3s; /* Smooth transition on hover */
    background-color: #f1f1f1; /* Light gray for all rows */
    border-bottom: 2px solid #000; /* Black line between each row */
  }
  
  /* Last row without bottom border */
  .summary-table-row:last-child {
    border-bottom: none; /* Remove bottom border for the last row */
  }
  
  /* Column label (left) styling with black line separation */
  .summary-table-label {
    align-items: flex-start;
    flex: 4; /* Take more space on the left (wider column) */
    font-weight: bold;
    padding: 15px; /* Maintain padding for readability */
    background-color: #879ea9; /* Light blue background for column labels */
    color: #fff; /* White text for better contrast */
    border-right: 2px solid #000; /* Black line between left and right columns */
    white-space: normal; /* Allow text wrapping */
    display: block; /* Ensure labels act as blocks for proper wrapping */
  }
  
  /* Value styling for individual rows (right column) */
  .summary-table-value {
    flex: 1; /* Takes less space on the right */
    padding: 15px; /* Maintain padding for readability */
    background-color: #fff; /* White background */
    color: #333; /* Darker text for better readability */
    border-left: 0; /* No left border */
    white-space: normal; /* Allow text wrapping */
    display: block; /* Ensure values act as blocks for proper wrapping */
  }
  
  /* General field styling inside values */
  .summary-table-field {
    margin-bottom: 0; /* Remove margin between fields */
  }
  
  /* Styling for text within the values */
  .summary-table-row .summary-table-field strong {
    font-weight: bold; /* Bold text for strong elements */
  }
  
  /* Media Queries for smaller screens */
  @media (max-width: 768px) {
    .summary-table-row {
      flex-direction: column; /* Stack items vertically on smaller screens */
      padding: 0; /* Adjust padding for smaller screens */
    }
    .summary-table-label,
    .summary-table-value {
      width: 100%; /* Full width for both on smaller screens */
      margin-right: 0; /* Remove margin on smaller screens */
    }
  }
  
  /* Responsive adjustments for font size */
  @media (max-width: 480px) {
    .summary-table-row {
      padding: 0; /* Less padding on smaller screens */
    }
    .summary-table-label,
    .summary-table-value {
      font-size: 14px; /* Smaller font size for compactness */
    }
  }






  .newtemplate-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 86vh;
    position: relative;
  }
  
  .newtemplate-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
  }
  
  .newtemplate-image {
    background-color: #081723;
    width: 50px;
    height: 68px;
    margin-right: 10px;
  }
  
  /* Button Container in the top-right corner */
  .newtemplate-button-container {
    position: absolute;
    top: 5px;
    right: 20px;
    text-align: center; /* Center align the button and line */
}

.newtemplate-button {
    padding: 3px 15px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    position: relative; /* Required for positioning the pseudo-element */
}

.newtemplate-button::after {
  content: '';
    display: block;
    width: 600%; /* Adjust the length of the line */
    height: 0.3px; /* Thickness of the line */
    background-color: #8b8888; /* Change color as needed */
    position: absolute;
    bottom: -3px; /* Adjust distance from button */
    left: -189%; /* Align to the center */
    transform: translateX(-50%); /* Center the line */
}
  
  .newtemplate-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  /* Card Container in the center of the page */
  .card-container {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 40px;
    margin: 190px 0;
    padding: 20px; /* Add padding to create space around the card container */
    /* margin-bottom:s 180px; */
    margin-top: 250px;
    padding-right: 580px;
  }
  
  /* Individual Card */
  .template-card {
    width: 270px; /* Increased width for a more spacious card */
    height: 220px;
    border: 1px solid #ccc;
    border-radius: 15px; /* Increased radius for softer curves */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added shadow for depth */
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s; /* Added shadow transition */
    text-align: center;
  }
  
  .template-card:hover {
    transform: translateY(-5px); /* Slight upward movement on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  }
  
  .card-image {
    width: 100%;
    border-top-left-radius: 15px; /* Increased radius for image */
    border-top-right-radius: 15px; /* Increased radius for image */
  }
  
  .card-body {
    padding: 15px; /* Increased padding for a more spacious feel */
  }
  
  .card-body h1 {
    margin: 10px 0;
    font-size: 1.5em; /* Slightly increased font size for title */
  }
  
  .card-body h4 {
    margin: 5px 0; /* Reduced margin for subtitle */
    color: #666; /* Subtle color for the subtitle */
  }
  
  /* Footer */
  .newtemplate-footer {
    text-align: center;
    padding: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    background-color: #f1f1f1;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  


  /* DueDiligence form display main container CSS */
  .oldtemplate-container {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .oldtemplate-header {
    display: flex;
    justify-content: center;
    align-items: flex-end; /* Align items to the bottom */
    padding: 20px 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 2; /* Adjust as needed for vertical space */
    padding-bottom: 10px; /* Positive padding for bottom space */
    padding-top: 10px; /* Positive padding for top space */
    padding-right: 10px;
}

  .oldtemplate-image {
    padding-left: 300px;
    /* background-color: #081723; */
    width: 340px; /* Adjust the image width */
    height: 61px; /* Adjust the image height */
    margin-right: 1px;

  }
  
  .oldtemplate-footer {
    text-align: center;
    padding: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    background-color: #f1f1f1;
    padding-left: 200px;
  }
  
.ishan{
  padding-left: 5px;
}


  /* reportfinaldisplay */


  .reportdatafinaldisplay {
    padding: 20px; /* Add any padding as needed */
    background-color: #f4f6f9; /* Set background color if needed */
  }
  
  .reportsdisplaymain {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
  }
  
  .reports-image {
    background-color: #081723;
    max-width: 49%;
    width: 50px;
    height: 68px;
    margin-right: 10px;
  }
  
  .reports-title {
    font-size: 24px;
    color: #333;
    text-align: center;
  }
  
  .reports-main-title {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    margin: 10px 0;
    color: #333;
  }
  
  .reports-subheading {
    text-align: center;
    font-size: 49px;
    font-weight: bold;
    margin: 9px 0;
    color: #66C379; /* Green color for subheading */
  }
  
  .reports-button-container {
    margin: 10px 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
  
  .bottom {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin: 0 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    background-color: #007BFF; /* Default background color */
    color: #fff; /* Text color */
  }
  
  .bottom:hover {
    background-color: #0056b3; /* Darker blue for hover */
  }
  
  .reports-footer {
    text-align: center;
    padding: 1px;
    font-size: 1px;
    font-weight: bold;
    color: #333;
    /* background-color: #f1f1f1; */
    width: 110%; /* Ensures footer stretches across the width */
    /* position: absolute; */
    bottom: 0;
  }
  
  .saving-container {
    display: flex;
    justify-content: space-between; /* Distributes space between buttons */
    align-items: flex-end; /* Aligns buttons at the bottom of the container */
    width: 100%; /* Adjust as needed */
    padding: 30px 0; /* Adds vertical space above and below the buttons */
  }
  
  .save-button{
    margin-top: 20px; /* Adjust this value to move buttons lower */
    background-color: black;
    width: 150px; /* Set a specific width */
  
    padding: 10px; /* Adjust as needed */
    border: none; /* Optional: Remove border */
    border-radius: 5px; /* Optional: Rounded corners */
    color: white;
  }
  .download-button {
    margin-top: 20px; /* Adjust this value to move buttons lower */
    background-color: black;
    color: white;
  }
  
  