* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.report-template {
  margin: 2% 4%;
  font-family: "Poppins", sans-serif;
}

.report-header {
  text-align: center;
  font-size: 26px;
  font-weight: 500;
  font-family: "Poppins";
}

.report-template h2 {
  font-size: 22px;
  font-family: "Poppins";
  font-weight: 500;
  margin: 2% 0;
}

.report-table {
  overflow: auto;
}

.report-template table {
  width: 100%;
  border: 1px solid #adadad;
  border-collapse: collapse;
} 

.report-template table th {
  padding: 10px;
  border: 1px solid #adadad;
  background: #D9D9D9;
  font-size: 16px;
}
.report-template table td {
  padding: 10px;
  border: 1px solid #adadad;
  font-size: 16px;
}

.report-template table input {
  padding: 1.5%;
  border: 1px solid #adadad;
  border-radius: 3px;
  width: 55% !important;
  margin-right: 10px;
}

.report-template .remove-column {
  padding: 4px 8px;
  border-radius: 3px;
  border: 1px solid #6C757D;
  background: #6C757D;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
}
.report-template .add-column {
  padding: 4px 8px;
  border-radius: 3px;
  border: 1px solid #007BFF;
  background: #007BFF;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
}

.report-template label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
}

.report-template textarea {
  width: 100%;
  padding: 1.5%;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
}

.report-flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
}

.report-flex input {
  width: 100%;
}

.report-flex div {
  width: 32%;
}

.report-template-body input{
  padding: 7px;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
}

.report-fields {
  margin-bottom: 2%;
}


.report-phone {
  display: flex; 
  margin-bottom: 10px; 
  flex-wrap: wrap !important;
}

.report-phone div {
  width: 32%;
}

.report-phone input {
  width: 70%;
  margin-right: 10px;
  margin-bottom: 10px;
}

.report-add-new input{
  margin-right: 10px;
  width: 80%;
}

.report-director input {
  width: 55%;
}

.report-footer {
  padding-top: 2%;
}

.report-footer button {
  margin-right: 10px;
}

.report-country {
  display: flex; 
  margin-bottom: 20px; 
  flex-wrap: wrap;
}

.report-country div {
  width: 40%;
}

.report-country input {
  width: 90%;
}


.report-extra input {
  margin-right: 10px;
  margin-bottom: 15px;
  width: 45%;
}

@media (max-width: 500px) {
  .report-phone {
    flex-wrap: wrap;
  }

  .report-phone div {
    width: 100%;
  }
}


.nav-tabs {
  list-style-type: none;
  padding-left: 0;
}

.nav-tabs .nav-item {
  display: inline-block; /* Ensures items are displayed horizontally */
}



.form-control {
  border-radius: 5px;
}

.pagination-controls button {
  margin: 0 5px;
  min-width: 40px;
}

.pagination-controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}