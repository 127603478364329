
@import url("https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto+Slab:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sen:wght@400..800&family=Vazirmatn:wght@100..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://fonts.cdnfonts.com/css/futura-lt');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.login-container{
  background-color: #081723;
}
.login-img{
  text-align: center;
}
.form-row{
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px #1B324F0F;
border-radius: 10px;
padding: 20px;
}
.form-row h4{
  font-family: "Clash Display", sans-serif;
font-size: 32px;
font-weight: 500;
line-height: 39.36px;
letter-spacing: 0.1em;
text-align: center;
color: #081723;
}
.form-row h6{
  font-family: 'Futura LT', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 33.07px;
text-align: center;
color: 
#666666;
margin-bottom: 20px;
}
.form-row label{
  font-family: "Poppins", sans-serif;
font-size: 18px;
font-weight: 400;
line-height: 32px;
color: #666666;
margin-bottom: 5px;
}
.form-row input:focus{
  box-shadow: none;
  outline: 0;
}

.form-row input{
  height: 55px;
  background-color: #F5F5F5;
  font-family: "poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.eye-btn{
  float: right;
  margin-top: -41px;
  font-size: 20px;
  margin-right: 10px;
  cursor: pointer;
}
.forgot{
  font-family: 'Inter', sans-serif;
font-size: 16px;
font-weight: 600;
line-height: 32px;
color: #081723;
text-decoration: none;
display: flex;
justify-content: end;
}
.login-btn{
  display: flex;
  justify-content: center;
}
.login-btn button{
  background-color: #081723;
  font-family: "Poppins", sans-serif;
font-size: 16px;
font-weight: 500;
line-height: 27px;
letter-spacing: 1px;
color: #fff;
padding: 10px 0;
border-radius: 3px;
width: 270px;
}

/*** SIDEBAR CSS ***/

.dapp-side-bar-nav {
  width: 245px;
  transition: left 0.3s ease; 
}
.dapp-side-bar-nav h5{
  font-family: 'Poppins';
font-size: 14.78px;
font-weight: 600;
line-height: 18.47px;
letter-spacing: -0.18472258746623993px;
text-align: left;
color: #fff;
margin: 10px 30px;
margin-top: 20px;
margin-bottom: 15px;
}
.dapp-side-bar-nav small{
  font-family: "Inter", sans-serif;
font-size: 12px;
font-weight: 500;
line-height: 12px;
letter-spacing: 0.4000000059604645px;
color: #FFFFFF80;
margin-left: 30px;
text-transform: uppercase;
}
.dapp-side-bar-nav.open {
  left: 0;
}

.toggle {
  cursor: pointer;
}

@media (max-width: 778px) {
  .dapp-side-bar-nav  {
    display: none; 
  }

  .toggle {
    display: block; 
  }

  .dapp-side-bar-nav.open  {
    display: block;
  }
}

 /* .dapp-side-bar-nav{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
} */
  section {
    position: fixed;
    width: 245px;
    height: 100vh;
    /* background: #252060; */
    background-color: #081723;
    /* box-shadow: 0px 10px 60px 0px rgba(226, 236, 249, 0.50); */
    transition: .5s;
    overflow-y: scroll;
    z-index: 9;
}
section::-webkit-scrollbar {
  display: none;
}
section {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
section.active {
    width: 270px;
}
section .active{
  /* background: #FFFFFF54;
  width: 92%;
  border-radius: 6px;
  height: 45px;
  margin-bottom: 13px; */
  margin-left: 10px;
}

section .active span{
  color: #fff;
  font-weight: 600;
  margin-left: -10px;
}
section span{
margin-top: -7px;
}
section .active .title{
margin-left: 1px;
font-weight: 600;
font-family: 'Inter', sans-serif;
font-size: 14px;
}
section .active i{
color: #fff;
}

/* section:hover {
    width: 300px;
} */

section ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 ;
}

section ul li {
    list-style: none;
    width: 100%;
    position: relative;
    white-space: nowrap; 
}
section li{
    margin: 5px 0;
}

section ul li a:hover {
    color: #fff;
}
section ul li a:hover i{
  color: #fff;
}
section ul li:hover a::before {
    /* background: #FFFFFF54; */
    border-radius: 6px;
    width: 92%;
    height: 45px;
    font-weight: 600;
}

section ul li a {
    position: relative;
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #FFFFFF80;
    transition: .2s;
    font-family: 'Inter', sans-serif;
    margin-left: 10px;
}

section ul li a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    transition: .2s;
}

section ul li a .icon {
    position: relative;
    display: block;
    min-width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}

section ul li a .title {
    position: relative;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
}

section ul li a .icon * {
    font-size: 17px;
}


.toggle {
    cursor: pointer;
}
.home-main-content{
  background-color: #fcfcfc;
    margin-left: 240px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
    padding-top: 20px;
}
header {
  background: transparent;
  }

  .toggle{
    display: none;
 }
 .sub-menu li{
   margin: 15px 0;
 }
 @media (max-width: 768px) {
  section {
    width: 60px;
    /* margin-top: 18%; */
    margin-top: -20px;
    margin-left: 0;
 }
    .home-main-content{
        margin-left:0;
        padding-left: 0;
        padding-right: 0;
        margin-right: 0;
    }
    header {
        display: flex;
        justify-content: left;
        align-items: center;
        padding: 10px 0;
        background: transparent;
        box-shadow: 0px 10px 60px 0px rgba(226, 236, 249, 0.50);
        color: #fff;
    }
 
    header a {
        color: #000;
    }
    .toggle{
    display: block;
 }
 .toggle i{
 position: absolute;
 left: 90%;
 color: #000;
 }
    section {
        left: -245px;
    }
 .header h3{
    padding-left: 0;
 }
    section.active {
        left: 0;
        /* width: 100%; */
        overflow-x: hidden;
    }
 
    section ul li a.toggle {
        display: none;
    }
 }
 
 nav{
  background-color: #081723;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  border-bottom: 1px solid #515151;
 }
 .profile-name{
  display: flex;
  gap: 20px;
 }
 .profile-name h6{
font-family: 'Poppins', sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 24px;
color: #fff;
 }
 .profile-name i{
  color:#fff;
 }
 .navy{
  width: 40px;
  height: 50px;
 }
.ms-2{
  /* padding-left: 20px; */
  position: relative;
  background-color: #081723;
  padding-right: 7px;
  padding-bottom: 2px;
  color: white; /* Add this line to set text color to white */
  padding-top: 2px; /* Add this line to shift text downward */
  /* padd */
}
 .hr-class{
  padding: 0 20px;
 }
 .colored-hr {
  border: 0;
  height: 1px;
  background-color: #fff; 
}
.dash-column{
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 20px 15px;
  height: 106px;
  margin: 10px 0;
  border: 1px solid #E6EDFF;
}
.dash-column h4{
  font-family: "Poppins", sans-serif;
font-size: 28px;
font-weight: 600;
line-height: 42px;
color: #000;
margin-bottom: 0;
}
.dash-column h6{
font-family: "Poppins", sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 24px;
color: #000;
margin-bottom: 0;
}
.dash-column img{
  box-shadow: 0px 2px 10px 0px #7C8DB51F;
}
.dash-column h5{
  font-family: "Futura LT", sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 30px;
text-align: left;
color: #347AE2;
margin-bottom: 0;
}
.kanban-heading{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.kanban-heading h6{
font-family: "Poppins", sans-serif;
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: -0.01em;
margin-bottom: 0;
}
.kanban-heading h4{
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  margin-bottom: 0;
  padding-left: 6.5px;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  color: #fff;
  background-color: 
  #066AFF;
}
.report-box{
  display: flex;
  justify-content: space-between;
}
.kanban-content{
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #fffbfb;
  padding: 10px;
  border: 1px solid #E6EDFF;
}
.report-box h5{
font-family: "Poppins", sans-serif;
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: -0.01em;
color: #000;
margin-bottom: 0;
}
.report-box span{
  font-family: "Poppins", sans-serif;
font-size: 11px;
font-weight: 500;
line-height: 20px;
letter-spacing: -0.01em;
color: 
#959BA3;
}
.kanban-content button{
  font-family: "Poppins";
font-size: 10px;
font-weight: 500;
line-height: 14px;
letter-spacing: -0.01em;
color: 
#60BF9D;
background: #EDFAF6;
padding: 8px 20px;
border-radius: 30px;
border: none;
margin-top: 20px;
}
.kanban-content{
  margin: 10px 0;
}
.list-box{
  display: flex;
  align-items: center;
}
.list-box h6{
  font-family: "Futura LT", sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 19.29px;
color: #232323;
margin-bottom: 0;
}
.doc-list{
  background-color: #fff;
  border: 1px solid #E6EDFF;
  padding: 15px 10px;
  border-radius: 10px;
}
.report-heading{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.report-heading h4{
  font-family: "Euclid Circular A", sans-serif;
font-size: 16px;
font-weight: 500;
line-height: 20.29px;
color: #000;
margin-bottom: 0;
}
.report-heading button{
  font-family: "Euclid Circular A", sans-serif;
font-size: 13px;
font-weight: 500;
line-height: 16.48px;
color: #fff;
background-color: #347AE2;
border-radius: 3px;
border: none;
padding: 10px 20px;
}
.template-box img{
  width: 100%;
  box-shadow: 0px 4px 4px 0px #00000040;
  opacity: 60%;
}
.template-box{
  margin: 10px 0;
}
.report-btn{
  display: flex;
  justify-content: center;
}
.report-btn button{
  position: absolute;
  margin-top: -200px;
  font-family: "Euclid Circular A", sans-serif;
font-size: 13px;
font-weight: 400;
line-height: 16.48px;
color: #fff;
background-color: #347AE2;
border-radius: 3px;
padding: 10px 20px;
border: none;
}

.vertical-tab button{
  background-color: transparent !important;
  text-align: left !important;
  font-family: "Poppins";
font-size: 15px;
font-weight: 500;
line-height: 22.5px;
color: rgba(124, 141, 181, 1);
margin: 10px 0;

}
.vertical-tab .active{
  color: #066AFF !important;
}
.vertical-tab i{
  padding-right: 10px;
  font-size: 20px;
}
.doc-head{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.doc-head h5{
font-family: "Poppins";
font-size: 14px;
font-weight: 500;
line-height: 20px;
text-align: left;
color: rgba(52, 64, 84, 1);
margin-bottom: 0;
}
.doc-head i{
  color: rgba(52, 122, 226, 1);
}
.doc-info{
  display: flex;
  justify-content: center;
  padding-top: 15px;
}
.document-box{
  border: 1px solid rgba(52, 122, 226, 1);
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 10px 0;
}
.add-btn button{
  background: rgba(233, 242, 255, 1);
border: none;
border-radius: 8px;
padding: 5px 20px;
font-family: "Clash Display", sans-serif;
font-size: 16px;
font-weight: 500;
line-height: 19.68px;
text-align: left;
color: rgba(52, 122, 226, 1);

}


.maintable-column{
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 5px;
  margin-left: 0px;
  padding: 20px;
}
.foot-tablerow h5{
  font-size: 25px;
  margin: 15px -15px;
  color: #545454;
}

#datatable {
width: 100% !important;
table-layout: auto !important;
border-collapse: collapse;
margin-top: 15px;
}

.table select{
  width: 100px;
  height: 30px;
}



#datatable th {
font-weight: 500;
font-size: 17px;
color: #666666;
border-bottom: 1px solid #f1f1f1;
padding: 12px !important;
text-align: left;
background-color: transparent; /* Ensures header background is white */
}

#datatable td:first-child {
text-align: center;
}

#datatable th:first-child {
text-align: center;
}

#datatable td {
font-weight: 400;
font-family: "Poppins", sans-serif;
font-size: 15px;
color: #666666;
border-bottom: 1px solid #f7f7f7;
padding: 15px 10px !important;
text-align: left;
word-wrap: break-word;
background: transparent !important;
}

#datatable td .td-span {
padding: 3px 16px;
border: 0.5px solid #38a3a5;
border-radius: 20px;
background: #38a3a565;
color: #000;
}

#datatable thead tr {
background-color: #f7f1f7; 
}
#datatable tbody tr:hover {
background-color: #f7f7f7 !important;
cursor: pointer;
}
#datatable tbody tr:nth-child(even) {
background-color: #f7f8f7; 
}

#datatable tbody tr:nth-child(odd) {
background-color: white;
}

.fa-pen-to-square {
padding: 5px;
color: #38a3a5;
border: 0.5px solid #38a3a5;
border-radius: 5px;
cursor: pointer;
}

.fa-eye {
  padding: 5px;
  color: #4d5fae;
  border: 0.5px solid #4d5fae;
  border-radius: 5px;
  cursor: pointer;
}

.fa-trash {
padding: 5px;
color: #d80000;
border: 0.5px solid #d80000;
border-radius: 5px;
cursor: pointer;
}


.table-banner {
margin-left: 17% !important;
/* padding: 5%; */
}
.dt-paging{
  display: none;
}
.dt-layout-row{
  /* display: flex ; */
  justify-content: space-between;
}
.dt-length{
  display:none;
}
.addemployee{
  display: flex;
  justify-content: end;
}

/*** login CSS ***/
.modal-header{
  border-bottom: none !important;
  padding-bottom: 0 !important;
}
.login-head h5{
  font-family: "Euclid Circular A", sans-serif;
 color: #292929;
  font-size: 24px;
  font-weight: 600;
}
.login-head p{
  color: #5D5454;
  font-family: "Euclid Circular A", sans-serif;
  font-weight: 500;
   font-size: 15px;
}

.login-form label{
  font-family: "Euclid Circular A", sans-serif;
  font-weight: 500;
   font-size: 15px;
  color: #4F4F4F;
  margin-bottom: 10px;
}
.login-form input{
  border: 1px solid #bdbdbd;
  height: 48px;
}
.login-form input:focus{
  box-shadow: none;
  border: 1px solid #8AC0FF;
}
.get-otp button{
  background-color: #2d2f31;
  border: none;
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  color: #fff;
  font-family: "Euclid Circular A", sans-serif;
  font-weight: 500;
   font-size: 15px;
}
.login-type {
  display: flex;
  justify-content: center;
}
.login-type h6 {
  width: 90%;
  text-align: center;
  border-bottom: 2px solid #B9B9B9;
  line-height: 0.1em;
  margin: 10px 0 20px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #6A707C;
}

.login-type h6 span {
  background: #fff;
  padding: 0 10px;
}
.google-login{
  display: flex;
  justify-content: center;
}
.google-login button{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
background-color: #fff;
border: none;
border-radius: 20px;
font-family: "Euclid Circular A", sans-serif;
font-size: 16px;
font-weight: 500;
line-height: 23.44px;
padding: 8px 30px;
border: 1px solid #E8ECF4;
}
.pass-toggle {
  float: right;
  margin-top: -35px;
  margin-right: 10px;
  cursor: pointer;
}
.login-option h5{
  font-family: "Euclid Circular A", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin: 10px 0;
  text-align: center;
}
.login-option button{
  padding: 0;
  border: none;
  background-color: transparent;
  color: #0A8FEF;
  font-family: "Euclid Circular A", sans-serif;
  font-size: 16px;
  font-weight: 500;
  
}
.resend-btn button{
  background-color: transparent;
  border: none;
  color: #0e599a;
}
.doc-table th{
color: red;
}






/* ishan css  */


.admin-sidebar-container {
  text-decoration: none; /* Use hyphenated property name */
  color: #FFFFFF80; /* No quotes needed */
  display: flex; 
  align-items: center; 
  margin-left: 32px; /* Use colon instead of semicolon */
  padding: 10px 0; /* No quotes needed */
}

.admin-sidebar-container1 {
  padding: 0;
  margin: 0;
  list-style-type: none; /* Use hyphenated property name */
  position: relative;
  color: #FFFFFF80; /* Change to 'color' instead of 'textColor' */
  z-index: 1000; /* Use hyphen instead of camel case */
}

.admin-sidebar-container2 {
  text-decoration: none; /* Use hyphenated property name */
  color: #FFFFFF80; /* No quotes needed */
  display: flex; 
  align-items: center; /* Use hyphenated property name */
}

.admin-sidebar-container3 {
  text-decoration: none; /* Use hyphenated property name */
  color: #FFFFFF80; /* No quotes needed */
  display: flex; 
  align-items: center; /* Use hyphenated property name */
}
.admin-sidebar-container4{
 margin-left: 10px;
}

.admin-sidebar-container5{
  cursor: pointer;
}